<template>
	<div>
    <div class="pb-3 pt-0" v-if="eventInfo && eventInfo.id">
      <span class="flex align-start flex-wrap">
        <button class="btn btn__small mr-3 mb-2" v-bind:class="{ 'btn__primary': showActive, 'btn__outlined': !showActive }" @click="showActivePipeline()">Active Pipeline</button>
        <button class="btn btn__small mr-3 mb-2" v-bind:class="{ 'btn__primary': showHired, 'btn__outlined': !showHired }" @click="showHiredPipeline()">Hired Workers</button>
        <button class="btn btn__small mr-3 mb-2" v-bind:class="{ 'btn__primary': showNotHired, 'btn__outlined': !showNotHired }" @click="showNotHiredPipeline()">Not Hired</button>
      </span>
    </div>
    <div>

      <Loader v-if="performingRequest" />

      <div class="dashboard__container--body" v-if="showActive">
        
        <div class="dashboard__container--body--col alt-col" style="width:100%; margin-bottom: 0;">
          <div class="whiteBack">
            <div class="flex align-center justify-space-between mb-3">
              <span>
                <h4>Add User to Pipeline</h4>
              </span>
            </div>
            <div class="flex justify-space-between align-center">
              <ais-instant-search :search-client="searchClient" index-name="a_users" style="width:100%; max-width: 640px;">
                <ais-search-box placeholder="Add User..." />
                <ais-state-results>
                  <template slot-scope="{ state: { query } }">
                    <ais-hits v-show="query.length > 0">
                      <template v-slot:item="{ item }">
                        <div>
                          <button @click="addUser(item)" class="btn btn__icon btn__flat mr-4">
                            </span>
                            <i class="fas fa-plus" style="color:blue;" v-if="!performingRequest"></i>
                            <i class="fa fa-spinner fa-spin" style="color:blue;" v-if="performingRequest"></i>
                          </button>
                          <div style="display: inline;">{{ item.firstName }} {{ item.lastName }} | <span v-if="item.address">{{item.address.city}}, {{item.address.state}} | </span>{{item.email}} | {{item.phone}}</div>
                        </div>
                      </template>
                    </ais-hits>
                  </template>
                </ais-state-results>
              </ais-instant-search>
            </div>
          </div>
        </div>
      </div>


      <div class="dashboard__container--body" v-if="showActive">
        <Loader v-if="!pipeline || pipeline.length == 0" />
        <h4 class="mt-5">Active Pipeline:</h4>
        <UserTable v-if="pipeline && pipeline.length >= 1" :groupUsers="pipeline" :group="eventInfo"  action="removePipeline" :showShiftPreference="true" :eventShifts="eventShifts" />
      </div>


      <div class="dashboard__container--body" v-if="showHired">
        <Loader v-if="!pipelineHired || pipelineHired.length == 0" />
        <h4 class="mt-2">Hired Pipeline:</h4>

        <UserTable v-if="pipelineHired && pipelineHired.length >= 1" :groupUsers="pipelineHired" :group="eventInfo" class="mt-3" action="addPipeline" :showShiftPreference="true" :eventShifts="eventShifts" />
      </div>
      <div class="dashboard__container--body" v-if="showNotHired">
        <Loader v-if="!pipelineCancelled || pipelineCancelled.length == 0" />
        <h4 class="mt-2">Not Hired From Pipeline:</h4>

        <UserTable v-if="pipelineCancelled && pipelineCancelled.length >= 1" :groupUsers="pipelineCancelled" :group="eventInfo" class="mt-3" action="addPipeline" :showShiftPreference="true" :cancelledPipe="true" :eventShifts="eventShifts" />
      </div>
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import UserTable from '@/components/UserTable.vue'
import * as moment from 'moment'
import router from '@/router'
import algoliasearch from 'algoliasearch/lite';
import ExportService from "@/services/ExportService"

export default {
  props: ['eventInfo'],
  name: 'eventPipeline',
  data() {
    return {
      showActive: true,
      showHired: false,
      showNotHired: false,
      performingRequest: false,
      batch: [],
      searchClient: algoliasearch(
        '0T1SIY6Y1V',
        'f03dc899fbdd294d6797791724cdb402',
      ),
    }
  },
  mounted() {
    if (!this.pipeline || this.pipeline.length == 0) {
      this.$store.dispatch('getPipeline', this.$route.params.id)
    }
    if (!this.eventShifts || this.eventShifts.length == 0) {
      this.$store.dispatch('getEventShiftsData', this.$route.params.id)
    }
    
  },
  components: {
    Loader,
    UserTable
  },
  computed: {
    ...mapState(['currentUser', 'userProfile', 'pipeline', 'pipelineCancelled', 'pipelineHired', 'eventShifts']),
  },
  methods: {
    showActivePipeline() {
      this.showActive = true
      this.showHired = false
      this.showNotHired = false
      // await this.$store.dispatch('clearPipelineHired')
      // await this.$store.dispatch('clearPipelineCancelled')
      if (!this.pipeline || this.pipeline.length == 0) {
        this.$store.dispatch('getPipeline', this.$route.params.id)
      }
      
    },
    showHiredPipeline() {
      this.showActive = false
      this.showHired = true
      this.showNotHired = false
      // await this.$store.dispatch('clearPipeline')
      // await this.$store.dispatch('clearPipelineCancelled')
      if (!this.pipelineHired || this.pipelineHired.length == 0) {
        this.$store.dispatch('getPipelineHired', this.$route.params.id)
      }
      
    },
    showNotHiredPipeline() {
      this.showActive = false
      this.showHired = false
      this.showNotHired = true
      // await this.$store.dispatch('clearPipelineHired')
      // await this.$store.dispatch('clearPipeline')
      if (!this.pipelineCancelled || this.pipelineCancelled.length == 0) {
        this.$store.dispatch('getPipelineCancelled', this.$route.params.id)
      }
      
    },
    addUser(item) {
      console.log(item)
      this.performingRequest = true;
      this.$store.dispatch('addToPipeline', {
        eventId: this.$route.params.id,
        userId: item.objectID
      })
      setTimeout(() => {
        this.performingRequest = false;
        this.$store.dispatch('getPipeline', this.$route.params.id)
      }, 500)
    },
    selectionChanged(params) {
      this.batch = params.selectedRows
    },
  },
  beforeDestroy () {
    // this.$store.dispatch('clearPipelines')
    // this.$store.dispatch('clearEventShiftsData')
    
    this.performingRequest = null
    delete this.performingRequest
    this.batch = null
    delete this.batch
  }
}
</script>

